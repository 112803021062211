exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-burbujita-js": () => import("./../../../src/pages/burbujita.js" /* webpackChunkName: "component---src-pages-burbujita-js" */),
  "component---src-pages-campaigns-js": () => import("./../../../src/pages/campaigns.js" /* webpackChunkName: "component---src-pages-campaigns-js" */),
  "component---src-pages-conversion-js": () => import("./../../../src/pages/conversion.js" /* webpackChunkName: "component---src-pages-conversion-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-transactional-js": () => import("./../../../src/pages/transactional.js" /* webpackChunkName: "component---src-pages-transactional-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

